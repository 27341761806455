import S3 from "aws-s3";

const fileConfig = {
    bucketName: process.env.REACT_APP_BUCKET_NAME
        ? process.env.REACT_APP_BUCKET_NAME
        : "",
    region: process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : "",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID
        ? process.env.REACT_APP_ACCESS_KEY_ID
        : "",
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
        ? process.env.REACT_APP_SECRET_ACCESS_KEY
        : "",
    s3Url: process.env.REACT_APP_S3_URL ? process.env.REACT_APP_S3_URL : "",
};

export const s3Uploader = (jobNumber: number, selectedFile: File) => {
    const S3Client = new S3(fileConfig);

    return S3Client.uploadFile(
        selectedFile,
        `job/${jobNumber}/` + selectedFile.name.split(".")[0]
    )
        .then(({ status }: { status: number }) => {
            return { status: status === 204, error: "" };
        })
        .catch((error: any) => {
            return {
                status: false,
                error: error,
            };
        });
};
