import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Snackbar,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { s3Uploader } from "../infrastructure/s3Uploader";
import { Controller, useForm } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        textAlign: "left",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
    },
    form: {
        maxWidth: theme.spacing(64),
        display: "inline-block",
    },
    input: {
        display: "none",
    },
}));

interface iFormFields {
    jobNumber: number | undefined;
}

const defaultValues: iFormFields = {
    jobNumber: undefined,
};

const schema = yup.object().shape({
    jobNumber: yup
        .number()
        .positive("Job number must be a positive number")
        .typeError("Job number should be a number")
        .required("Job number is required."),
});

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const Dashboard = () => {
    const classes = useStyles();
    const query = useQuery();
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [isLoadingUpload, setLoadingUpload] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const {
        formState: { isValid, errors },
        handleSubmit,
        control,
        reset,
    } = useForm<iFormFields>({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: "all",
    });

    useEffect(() => {
        if (!initialized && query.get("job")) {
            reset({ jobNumber: parseInt(query.get("job") || "0") });
            setInitialized(true);
        }
    }, [initialized, query, reset]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const file = event.target.files[0];
        setSelectedFile(file);

        // if (!watch("fileName")) {
        //     reset({
        //         jobNumber: watch("jobNumber"),
        //         fileName: file.name.split(".")[0],
        //     });
        // }
    };

    const onUpload = async (data: iFormFields) => {
        if (!(data.jobNumber && selectedFile) || !isValid) return;
        setOpen(false);
        setLoadingUpload(true);

        const result = await s3Uploader(data.jobNumber, selectedFile);
        if (result.status) {
            setLoadingUpload(false);
            setOpen(true);
        }
    };

    const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Successfully upload a file!
                </Alert>
            </Snackbar>
            <form className="form" onSubmit={handleSubmit(onUpload)}>
                <Box className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="jobNumber"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Job Number"
                                        variant="filled"
                                        multiline
                                        fullWidth
                                        error={!!errors.jobNumber}
                                        helperText={
                                            errors?.jobNumber?.message
                                                ? errors.jobNumber.message
                                                : ``
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                        <Controller
                            name="fileName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="File name"
                                    variant="filled"
                                    multiline
                                    fullWidth
                                    error={!!errors.fileName}
                                    helperText={
                                        errors?.fileName?.message
                                            ? errors.fileName.message
                                            : ``
                                    }
                                />
                            )}
                        />
                    </Grid> */}
                        {!selectedFile ? (
                            <Grid item xs={12}>
                                <label>No file selected</label>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Box className={classes.description}>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        Original file name: {selectedFile.name}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                    >
                                        File type: {selectedFile.type}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <input
                                id="buttonFile"
                                className={classes.input}
                                type="file"
                                accept="*"
                                onChange={onFileChange}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    fullWidth
                                >
                                    Select File
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={
                                    isLoadingUpload || !isValid || !selectedFile
                                }
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </>
    );
};
