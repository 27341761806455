import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { NotFound } from "./NotFound";

export const Content = () => {
    return (
        <Switch>
            <Route path={"/"} exact component={Dashboard} />
            <Route path="" component={NotFound} />
        </Switch>
    );
};
