import React from "react";
import {
    Container,
    Box,
    Typography,
    makeStyles,
    Button,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { NavLink as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: 80,
    },
    mainBox: {
        minHeight: `calc(100vh - ${theme.spacing(32)}px)`,
    },
}));

export const NotFound = () => {
    const classes = useStyles();

    return (
        <Container>
            <Box
                className={classes.mainBox}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box>
                    <Typography align="center">
                        <WarningIcon className={classes.icon} color="error" />
                    </Typography>
                    <Typography variant="h3" align="center">
                        Page not found.
                    </Typography>
                    <Typography gutterBottom align="center">
                        You may have mistyped the address or the page may have
                        moved.
                    </Typography>
                    <Box my={4} textAlign="center">
                        <Button
                            variant="contained"
                            color="primary"
                            component={RouterLink}
                            to={`/`}
                        >
                            Back to home
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
